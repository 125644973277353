import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import GroupCustomers from "./pages/GroupCustomers";
import Demo from "./pages/Demo";
import DocumentPolitykaPrywatnosci from "./pages/PolitykaPrywatnosci";
import DocumentRegulaminSerwisu from "./pages/RegulaminSerwisu";
import DocumentObowiazekInformacyjny from "./pages/ObowiazekInformacyjny";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/klienci-ifil" element={<GroupCustomers />} />
            <Route path="/demo" element={<Demo />} />
            <Route
              path="/polityka-prywatnosci"
              element={<DocumentPolitykaPrywatnosci />}
            />
            <Route
              path="/regulamin-serwisu"
              element={<DocumentRegulaminSerwisu />}
            />
            <Route
              path="/obowiazek-informacyjny"
              element={<DocumentObowiazekInformacyjny />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
