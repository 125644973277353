function Footer() {
  return (
    <>
      <footer className="px-6 lg:px-36 flex flex-col py-12 relative z-20 bg-transparent">
        <div className="flex flex-col items-center justify-center w-full gap-6 lg:gap-12">
          <span className="w-full bg-gray-300 h-[1px]"></span>
          <img
            width="1000px"
            height="1000px"
            className="w-6"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/contractorflow-pl/sygnet.webp"
            alt=""
          />
        </div>
        <div className="py-6 lg:py-12 flex flex-col lg:flex-row justify-between gap-6 lg:gap-0 text-left">
          <div className="flex text-sm flex-col gap-1 lg:gap-2">
            <p className="">Dokumenty</p>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="/polityka-prywatnosci"
            >
              Polityka Prywatności
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="/regulamin-serwisu"
            >
              Regulamin Serwisu
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="/obowiazek-informacyjny"
            >
              Obowiązek Informacyjny
            </a>
          </div>
          <div className="flex text-sm flex-col gap-1 lg:gap-2">
            <p className="">Dodatkowe usługi</p>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://ifil.pl/strony-internetowe"
            >
              Tworzenie stron internetowych
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://ifil.pl/sklepy-internetowe"
            >
              Tworzenie sklepów internetowych
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://ifil.pl/aplikacje"
            >
              Aplikacje internetowe dla firm
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://www.lh.pl/hosting?ref=ifil"
            >
              Szybkie serwery dla firm
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://www.lh.pl/domeny?ref=ifil"
            >
              Tanie domeny internetowe
            </a>
          </div>
          <div className="flex text-sm flex-col gap-1 lg:gap-2">
            <p className="">Przydatne linki</p>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="/"
            >
              Home
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="#cennik"
            >
              Cennik
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="/kontakt"
            >
              Kontakt
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="#faq"
            >
              FAQ
            </a>
          </div>
          <div className="flex text-sm flex-col gap-1 lg:gap-2">
            <p className="">Należymy do iFil</p>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://ifil.pl"
            >
              iFil Group
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://letsoffer.pl"
            >
              Let's Offer
            </a>
            <a
              className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in"
              href="https://managly.pl"
            >
              Managly
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-6 text-left">
          <div className="gradient-box-1 p-6 lg:p-12 rounded-xl flex flex-col gap-2">
            <p className="text-xl font-semibold secondColor">
              Aplikacja do ofertowania
            </p>
            <p className="text-gray-400">
              Sprawdź naszą autorską aplikację do tworzenia spersonalizowanych
              ofert w 90 sekund.
            </p>
            <a href="https://letsoffer.pl">
              <button className="button-main">Poznaj Let's Offer</button>
            </a>
          </div>
          <div className="bg-gray-400 overflow-hidden rounded-xl relative flex flex-col gap-2">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/contractorflow-pl/contractor-flow%20(1).webp"
              alt=""
              className="absolute w-full h-full object-cover z-10"
            />
            <div className="absolute w-full h-full bg-main opacity-80 z-20"></div>
            <div className="w-full h-full flex flex-col p-6 lg:p-12 gap-2 z-30">
              <p className="text-xl font-semibold text-white">
                Uzyskaj bezpłatny dostęp
              </p>
              <p className="text-white opacity-90">
                Skorzystaj z usług iFil Group i zyskaj bezpłatny dostęp do
                ContractorFlow!
              </p>
              <a href="/klienci-ifil">
                <button className="button-white">Dowiedz się więcej</button>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full mt-12 flex flex-col lg:flex-row items-center justify-between text-sm">
          <a
            href="https://ifil.pl"
            className="opacity-50 hover:opacity-80 cursor-pointer transition duration-300 ease-in mb-4 lg:mb-0"
          >
            iFil Group by Filip Kania
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
